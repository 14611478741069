import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { lighten, darken, mix } from 'polished';
import React from 'react';
import svgToDataUrl from '../../helpers/svg-to-data-url';
import isPropValid from '@emotion/is-prop-valid';

const arrowSvg = ({ color }, quote) =>
  svgToDataUrl(
    `
  <svg fill="${color}" width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.4767 6.16664L6.00671 1.69664L7.18504 0.518311L13.6667 6.99998L7.18504 13.4816L6.00671 12.3033L10.4767 7.83331H0.333374V6.16664H10.4767Z"/>
  </svg>
`,
    quote
  );

const getVars = ({ theme, variant }) => {
  let textColor = theme.colors[theme.complementaryColors.bahamaBlue];
  const defaults = {
    '--color': theme.colors.bahamaBlue,
    '--hover-color': lighten(0.2, theme.colors.bahamaBlue),
    '--active-color': lighten(0.4, theme.colors.bahamaBlue),
    '--text-color': textColor,
    '--text-hover-color': textColor,
    '--text-active-color': textColor,
    '--border-color': theme.colors.transparent,
    '--border-thickness': 0,
    '--border-hover-color': theme.colors.transparent,
    '--border-hover-thickness': 0,
    '--border-active-color': theme.colors.transparent,
    '--border-active-thickness': 0,
    '--disabled-color': 'var(--active-color)',
    '--text-disabled-color': 'var(--text-active-color)',
    '--border-disabled-color': 'var(--border-active-color)',
  };
  switch (variant) {
    case 'primary':
      textColor = theme.colors[theme.complementaryColors.pacificBlue];
      return {
        ...defaults,
        '--color': theme.colors.pacificBlue,
        '--hover-color': lighten(0.2, theme.colors.pacificBlue),
        '--active-color': lighten(0.4, theme.colors.pacificBlue),
        '--text-color': textColor,
        '--text-hover-color': textColor,
        '--text-active-color': textColor,
      };
    case 'emerald':
      textColor = theme.colors[theme.complementaryColors.emerald];
      return {
        ...defaults,
        '--color': theme.colors.emerald,
        '--hover-color': lighten(0.1, theme.colors.emerald),
        '--active-color': lighten(0.2, theme.colors.emerald),
        '--text-color': textColor,
        '--text-hover-color': textColor,
        '--text-active-color': textColor,
      };
    case 'ghost':
      return {
        ...defaults,
        '--color': theme.colors.transparent,
        '--hover-color': theme.colors.transparent,
        '--active-color': theme.colors.transparent,
        '--text-color': theme.colors.bahamaBlue,
        '--text-hover-color': theme.colors.bahamaBlue,
        '--text-active-color': theme.colors.bahamaBlue,
        '--border-color': theme.colors.quartz,
        '--border-thickness': '1px',
        '--border-hover-color': mix(
          0.5,
          theme.colors.quartz,
          theme.colors.bahamaBlue
        ),
        '--border-hover-thickness': '1px',
        '--border-active-color': theme.colors.bahamaBlue,
        '--border-active-thickness': '2px',
        '--text-disabled-color': mix(0.5)(theme.colors.bahamaBlue)(
          theme.colors.white
        ),
        '--border-disabled-color': mix(0.5)(theme.colors.quartz)(
          theme.colors.white
        ),
      };
    case 'alice':
      return {
        ...defaults,
        '--color': theme.colors.aliceBlue,
        '--hover-color': darken(0.1, theme.colors.aliceBlue),
        '--active-color': darken(0.2, theme.colors.aliceBlue),
        '--text-color': theme.colors.pacificBlue,
        '--text-hover-color': theme.colors.pacificBlue,
        '--text-active-color': theme.colors.pacificBlue,
        '--border-color': theme.colors.quartz,
        '--border-thickness': '1px',
        '--border-hover-color': mix(
          0.5,
          theme.colors.quartz,
          theme.colors.pacificBlue
        ),
        '--border-hover-thickness': '1px',
        '--border-active-color': theme.colors.pacificBlue,
        '--border-active-thickness': '2px',
      };
    case 'text':
      return {
        ...defaults,
        '--color': theme.colors.transparent,
        '--hover-color': theme.colors.transparent,
        '--active-color': theme.colors.transparent,
        '--text-color': theme.colors.primary,
        '--text-hover-color': lighten(0.2, theme.colors.bahamaBlue),
        '--text-active-color': theme.colors.bahamaBlue,
      };
    default:
      return defaults;
  }
};

export const buttonStyle = ({
  theme,
  variant = 'secondary',
  large = false,
}) => {
  const vars = getVars({ theme, variant });
  return css`
    ${vars}
    ${theme.typography.controlStrong}
    color: var(--text-color);
    background-color: var(--color);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;

    ${variant === 'text'
      ? css`
          ${theme.typography.underline};
        `
      : css`
          border-radius: 4px;
          user-select: none;
          height: ${large ? '48px' : '40px'};
          padding: 3px 20px;
        `}

    & > .arrow {
      margin-left: 14px;
      transition: transform 150ms ease-in-out;
    }

    & > .arrow-left {
      margin-right: 14px;
      transition: transform 150ms ease-in-out;
    }

    &.icon-btn > .arrow,
    &.icon-btn > .arrow-left {
      margin: 0;
    }

    box-shadow: inset 0px 0px 0px var(--border-thickness) var(--border-color);
    &.disabled {
      pointer-events: none;
      cursor: default;
      background-color: var(--disabled-color);
      color: var(--text-disabled-color);
      border-color: var(--border-disabled-color);
    }
    transition: background-color 150ms ease-out, box-shadow 150ms ease-out,
      border 150ms ease-out, color 150ms linear, opacity 250ms linear;
    ${theme.media.hover} {
      &:not(.disabled):hover {
        background-color: var(--hover-color);
        color: var(--text-hover-color);
        box-shadow: inset 0px 0px 0px var(--border-hover-thickness)
          var(--border-hover-color);
        & > .arrow {
          transform: translateX(7px);
        }
        & > .arrow-left {
          transform: translateX(-7px);
        }
      }
    }
    &:not(.disabled):active,
    &.active {
      background-color: var(--active-color);
      color: var(--text-active-color);
      box-shadow: inset 0px 0px 0px var(--border-active-thickness)
        var(--border-active-color);
      & > .arrow {
        transform: translateX(4px);
      }
      & > .arrow-left {
        transform: translateX(-4px);
      }
    }
  `;
};

// eslint-disable-next-line react/display-name
const Component = React.forwardRef(({ as: T = 'a', ...props }, ref) => (
  <T {...props} ref={ref} />
));

export default styled(Component, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'large',
})`
  ${buttonStyle}
`;
